import React from "react";

const Header = ({ text, children, tabs, style, containerStyle }) => {
  return (
    <div className={`bg-white border border-[#E1E6E1] p-5 rounded-[8px] ${containerStyle} `}>
      <div className=" flex justify-between items-center gap-[8px]">
        <p
          style={style}
          className="text-primary text-lg font-semibold leading-6"
        >
          {text}
        </p>
        <div>{children}</div>
      </div>

      <div>{tabs}</div>
    </div>
  );
};

export default Header;
