import React,{useState} from 'react';
import Header from '../../components/Header';
import { Clear, ExpandMore } from "@mui/icons-material";


const TeammatePage = () => {

    const [activeTab, setActiveTab] = useState("teammates");


    return (  
        <>
        <Header text="Teammate Management">
        <div>
          <button
            className="w-full py-3 flex items-center justify-center px-4 hover:bg-opacity-[0.8] bg-[#EDF7EE] rounded-[8px] text-sm font-medium transition-all"
          >
            <p className="text-[#2B8C34] font-medium text-sm">
              Invite Teammate
            </p>
          </button>
        </div>
       </Header>

       <div className='mt-5 bg-white rounded-[8px] border border-[#E1E6E1]'>
            <div className='flex py-4 px-4 justify-between items-center border-b border-[#E1E6E1]'>
                <div>
                    <p className='text-[#4B564D] font-semibold text-sm'>Invited Teammates</p>
                </div>
                <div className="flex items-center border border-[#E1E6E1] bg-[#F6F7F6] rounded-lg overflow-hidden w-fit">
                <button
                    className={`px-4 py-2 text-sm font-semibold ${
                    activeTab === "teammates"
                        ? "bg-white text-[#161D17]"
                        : "text-[#5F6D60]"
                    } rounded-lg transition`}
                    onClick={() => setActiveTab("teammates")}
                >
                    Teammates
                </button>
                <button
                    className={`px-4 py-2 text-sm font-semibold ${
                    activeTab === "pending"
                        ? "bg-white text-[#161D17]"
                        : "text-[#5F6D60]"
                    } rounded-lg transition`}
                    onClick={() => setActiveTab("pending")}
                >
                    Pending Invitation
                </button>
                </div>
            </div>
            <div className='py-4 px-4'>
                {/* tables */}
                 <div className="overflow-x-auto relative">
                    <table className="w-full text-left">
                        <thead className="rounded-lg bg-[#F6F7F6] text-sm border border-[#E1E6E1]">
                        <tr>
                            <th scope="col" className="px-4 py-4 text-[#5F6D60] font-semibold ">
                            Name
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                            Email Address
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                            Department
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                            Role
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                            
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-[#4B564D] text-sm border-[#E1E6E1]">
                              <tr
                                className="bg-white text-sm"
                                >
                                <th
                                    scope="row"
                                    className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                <div className='flex gap-2 items-center'>
                                    <div className='bg-[#2B8C34] text-[#FFFFFF] text-xs font-medium w-[32px] h-[32px] flex justify-center items-center rounded-full'>
                                        AK
                                    </div>
                                    <div>
                                        <p className='text-sm text-[#2E332F] font-semibold'>Adelabu Micheal</p>
                                    </div>
                                </div>
                                </th>
                                <td className="py-4 px-4 text-[#5F6D60] font-semibold">
                                     Adelabumichea@crop2cash.com.ng
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                 <div className='bg-[#DBFFDF] rounded-[28px] text-[#2B8C34] text-sm font-semibold py-1 px-2 w-fit'>
                                        Sales
                                 </div>
                                 </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                    <div className='flex gap-1 items-center'>
                                        <div>
                                            <p className='text-[#2E332F] font-semibold text-sm'>Manager</p>
                                        </div>
                                        <div className='cursor-pointer'>
                                             <ExpandMore style={{ color: "#5F6D60", fontSize: "18px" }} />
                                        </div>
                                    </div>
                                </td>
                                <td className="py-4 px-4 cursor-pointer">
                                     <Clear style={{ fontSize: "16px", color: '#5F6D60' }} />    
                                </td>
                            </tr>
                            <tr
                                className="bg-white text-sm"
                                >
                                <th
                                    scope="row"
                                    className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                <div className='flex gap-2 items-center'>
                                    <div className='bg-[#2B8C34] text-[#FFFFFF] text-xs font-medium w-[32px] h-[32px] flex justify-center items-center rounded-full'>
                                        CA
                                    </div>
                                    <div>
                                        <p className='text-sm text-[#2E332F] font-semibold'>Wilson Kate</p>
                                    </div>
                                </div>
                                </th>
                                <td className="py-4 px-4 text-[#5F6D60] font-semibold">
                                Katewilson@crop2cash.com.ng
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                 <div className='bg-[#DBFFDF] rounded-[28px] text-[#2B8C34] text-sm font-semibold py-1 px-2 w-fit'>
                                        Marketing
                                 </div>
                                 </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                    <div className='flex gap-2 items-center'>
                                        <div>
                                            <p className='text-[#2E332F] font-semibold text-sm'>Member</p>
                                        </div>
                                        <div className='cursor-pointer'>
                                             <ExpandMore style={{ color: "#5F6D60", fontSize: "18px" }} />
                                        </div>
                                    </div>
                                </td>
                                <td className="py-4 px-4 cursor-pointer">
                                     <Clear style={{ fontSize: "16px", color: '#5F6D60' }} />    
                                </td>
                            </tr>
                            <tr
                                className="bg-white text-sm"
                                >
                                <th
                                    scope="row"
                                    className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                <div className='flex gap-2 items-center'>
                                    <div className='bg-[#2B8C34] text-[#FFFFFF] text-xs font-medium w-[32px] h-[32px] flex justify-center items-center rounded-full'>
                                        NY
                                    </div>
                                    <div>
                                        <p className='text-sm text-[#2E332F] font-semibold'>Jones Smith</p>
                                    </div>
                                </div>
                                </th>
                                <td className="py-4 px-4 text-[#5F6D60] font-semibold">
                                Jonesmith@crop2cash.com.ng
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                 <div className='bg-[#DBFFDF] rounded-[28px] text-[#2B8C34] text-sm font-semibold py-1 px-2 w-fit'>
                                        Customer Support
                                 </div>
                                 </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                    <div className='flex gap-2 items-center'>
                                        <div>
                                            <p className='text-[#2E332F] font-semibold text-sm'>Member</p>
                                        </div>
                                        <div className='cursor-pointer'>
                                             <ExpandMore style={{ color: "#5F6D60", fontSize: "18px" }} />
                                        </div>
                                    </div>
                                </td>
                                <td className="py-4 px-4 cursor-pointer">
                                     <Clear style={{ fontSize: "16px", color: '#5F6D60' }} />    
                                </td>
                            </tr>
                        </tbody>
                     </table>
                </div>

            </div>

       </div>

     </>
    );
}
 
export default TeammatePage;