import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../config";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

///// ------- fetch Overview Statistics
export const getOverviewStats = createAsyncThunk(
  "getOverviewStats",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}overview/stats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// fetch insights
export const getOverviewInsights = createAsyncThunk(
  "getOverviewInsights",
  async ({ from, to }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}overview/insights?from=${from}&to=${to}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// get recent transactions
export const getRecentTransactions = createAsyncThunk(
  "getRecentTransactions",
  async ({ limit, type }, { rejectWithValue, dispatch }) => {
    try {
      if (type === "all") {
        const res = await axios.get(
          `${apiUrl}wallet_transactions?limit=${limit}&page=1`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } else {
        const res = await axios.get(
          `${apiUrl}wallet_transactions?limit=${limit}&type=${type}&page=1`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// get recent orders
export const getRecentOrders = createAsyncThunk(
  "getRecentOrders",
  async ({ type }, { rejectWithValue, dispatch }) => {
    try {
      if (type === "all") {
        const res = await axios.get(`${apiUrl}orders?page=1`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res?.data;
        }
      } else {
        const res = await axios.get(`${apiUrl}${type}?page=1`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// get bar charts data
export const getBarChartsData = createAsyncThunk(
  "getBarChartsData",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}overview/farmers-bar-chart?year=2023`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
