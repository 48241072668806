import React, { useState } from "react";
import { Form, Formik } from "formik";
import eye_off from "../../assets/icons/eye-off.svg";
import eye from "../../assets/icons/eye.svg";
import greencheck from "../../assets/icons/green-check.svg";
import warning from "../../assets/icons/warning.svg";
import { useNavigate } from "react-router-dom";
import OnboardingSidebar from "../../components/OnboardingSidebar/sidebar";
import { joinOrganisationValidator } from "../../validationSchema/validator";

const JoinOrganisation = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();

  const [requirements, setRequirements] = useState({
    hasEightChars: false,
    hasNumber: false,
    hasLowerCase: false,
    hasUpperCase: false,
  });

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleSubmit = (values) => {
    navigate("/");
  };

  return (
    <div>
      <div className="relative flex overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[350px] h-[350px] rounded-full blur-[150px]"></div>
        <OnboardingSidebar />
        <div className="w-full md:w-[60%] px-4 md:px-0 flex justify-center flex-col min-h-screen">
          <div className="min-w-full md:min-w-[32rem] mx-auto">
            <h6 className="text-primary font-semibold text-2xl">
              You've been invited!
            </h6>
            <p className="text-[#5C715E] text-sm mt-2 font-medium max-w-lg">
              Enter your credentials below to get started{" "}
            </p>

            {/* Display email and Department */}

            <div className="px-4 py-3 flex items-center h-[72px] border rounded-lg border-[#E1E6E1] rounded-8px mt-7">
              {/* Abbreviation of name */}
              <div className="w-[48px] h-[48px] flex justify-center items-center bg-[#2B8C34] text-white rounded-full">
                AD
              </div>

              <div className="ml-2 flex flex-col justify-center">
                <p className="text-[#5F6D60] text-sm mb-1 font-medium">
                  Adelabumichea@crop2cash.com.ng
                </p>
                <div className="text-[#2B8C34] border border-[#2B8C34] text-sm font-semibold w-[50px] h-[24px] flex justify-center items-center rounded-full">
                  Sales
                </div>
              </div>
            </div>

            <div className="max-w-[80%]">
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={joinOrganisationValidator}
                initialValues={{
                  firstname: "",
                  lastname: "",
                  password: "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  isValid,
                  dirty,
                }) => (
                  <Form className="mt-8" onSubmit={handleSubmit}>
                    <div className="flex gap-4">
                      {/* First Name */}
                      <div className="flex-1">
                        <label
                          htmlFor="firstname"
                          className="block mb-2 text-[#344335] text-sm font-normal"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          name="firstname"
                          placeholder="First Name"
                          className={
                            touched.firstname && errors.firstname
                              ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                              : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                          }
                          value={values.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.firstname && errors.firstname ? (
                          <div className="flex">
                            <img src={warning} className="" alt="warning" />
                            <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                              {touched.firstname && errors.firstname}
                            </small>
                          </div>
                        ) : null}
                      </div>
                      {/* Last Name */}
                      <div className="flex-1">
                        <label
                          htmlFor="lastname"
                          className="block mb-2 text-[#344335] text-sm font-normal"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="lastname"
                          placeholder="Last Name"
                          className={
                            touched.lastname && errors.lastname
                              ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                              : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                          }
                          value={values.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.lastname && errors.lastname ? (
                          <div className="flex">
                            <img src={warning} className="" alt="warning" />
                            <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                              {touched.lastname && errors.lastname}
                            </small>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="password"
                        className="block mb-2 text-secondary text-sm font-normal"
                      >
                        Password
                      </label>
                      <div className="relative">
                        <input
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          className={
                            touched.password && errors.password
                              ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                              : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                          }
                          value={values.password}
                          onChange={(e) => {
                            handleChange(e);
                            const password = e.target.value;

                            // Update requirements directly
                            setRequirements({
                              hasEightChars: password.length >= 8,
                              hasNumber: /[0-9]/.test(password),
                              hasLowerCase: /[a-z]/.test(password),
                              hasUpperCase: /[A-Z]/.test(password),
                            });
                          }}
                          onBlur={handleBlur}
                        />
                        <img
                          onClick={togglePasswordVisiblity}
                          src={passwordShown ? eye_off : eye}
                          alt="eye"
                          className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                        />
                      </div>
                      {touched.password && errors.password ? (
                        <div className="flex">
                          <img src={warning} className="" alt="warning" />
                          <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                            {touched.password && errors.password}
                          </small>
                        </div>
                      ) : null}
                    </div>
                    {/* Validation Checks */}
                    <div className="flex flex-col gap-1 mt-3">
                      <p className="text-[#5C715E] text-sm font-semibold max-w-lg">
                        Your password must contain at least:
                      </p>
                      <div className="flex gap-2">
                        <div
                          className={
                            requirements.hasEightChars
                              ? "flex items-center justify-center gap-2 border border-[#2B8C34] rounded-md py-1 px-2"
                              : " border border-[#96A397] rounded-md py-1 px-2"
                          }
                        >
                          <p
                            className={
                              requirements.hasEightChars
                                ? "text-[#2B8C34] text-xs font-medium whitespace-nowrap"
                                : "text-[#96A397] text-xs font-medium whitespace-nowrap"
                            }
                          >
                            Eight Characters
                          </p>
                          {requirements.hasEightChars ? (
                            <img className="h-4" src={greencheck} alt="+" />
                          ) : null}
                        </div>
                        <div
                          className={
                            requirements.hasNumber
                              ? " flex items-center justify-center gap-2  border border-[#2B8C34] rounded-md py-1 px-2"
                              : " border border-[#96A397] rounded-md py-1 px-2"
                          }
                        >
                          <p
                            className={
                              requirements.hasNumber
                                ? "text-[#2B8C34] text-xs font-medium whitespace-nowrap"
                                : "text-[#96A397] text-xs font-medium whitespace-nowrap"
                            }
                          >
                            One Numeric Character
                          </p>
                          {requirements.hasNumber ? (
                            <img className="h-4" src={greencheck} alt="+" />
                          ) : null}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div
                          className={
                            requirements.hasLowerCase
                              ? "flex items-center justify-center gap-2  border border-[#2B8C34] rounded-md py-1 px-2"
                              : "border border-[#96A397] rounded-md py-1 px-2"
                          }
                        >
                          <p
                            className={
                              requirements.hasLowerCase
                                ? "text-[#2B8C34] text-xs font-medium whitespace-nowrap"
                                : "text-[#96A397] text-xs font-medium whitespace-nowrap"
                            }
                          >
                            One Lowercase Character{" "}
                          </p>
                          {requirements.hasLowerCase ? (
                            <img className="h-4" src={greencheck} alt="+" />
                          ) : null}
                        </div>
                        <div
                          className={
                            requirements.hasUpperCase
                              ? " flex items-center justify-center gap-2  border border-[#2B8C34] rounded-md py-1 px-2"
                              : " border border-[#96A397] rounded-md py-1 px-2"
                          }
                        >
                          <p
                            className={
                              requirements.hasUpperCase
                                ? "text-[#2B8C34] text-xs font-medium whitespace-nowrap"
                                : "text-[#96A397] text-xs font-medium whitespace-nowrap"
                            }
                          >
                            One Uppercase Character
                          </p>
                          {requirements.hasUpperCase ? (
                            <img className="h-4" src={greencheck} alt="+" />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* Create Account Button */}
                    <div className="mt-6">
                      <button
                        type="submit"
                        disabled={!(isValid && dirty)}
                        className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                      >
                        Create Account
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinOrganisation;
