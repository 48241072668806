export const OverviewBreadcrumb = [
  {
    text: "Overview",
    href: `/overview`,
    disabled: false,
  },
];

export const CatalogBreadcrumb = [
  {
    text: "Product Catalogue",
    href: `/product-catalogue`,
    disabled: false,
  },
];

export const inputBreadcrumb = [
  {
    text: "Product Catalogue",
    href: `/product-catalogue`,
    disabled: true,
  },
  {
    text: "Input",
    href: ``,
    disabled: false,
  },
];

export const addProductBreadcrumb = [
  {
    text: "Product Catalogue",
    href: `/product-catalogue`,
    disabled: true,
  },
  {
    text: "Add product",
    href: ``,
    disabled: false,
  },
];

export const CallRequestBreadcrumb = [
  {
    text: "Call Request",
    href: `/call-request`,
    disabled: false,
  },
];

export const CallLogBreadcrumb = [
  {
    text: "Call Log",
    href: `/call-log`,
    disabled: false,
  },
];

export const CallAgentBreadcrumb = [
  {
    text: "Call Log",
    href: `/call-log-admin`,
    disabled: true,
  },
  {
    text: "Call Agent",
    href: `/call-log-admin/agent/1`,
    disabled: false,
  },
];

export const CallLogsBreadcrumb = [
  {
    text: "Call Log",
    href: `/call-log-admin`,
    disabled: true,
  },
  {
    text: "Call Logs Created",
    href: `/call-log-admin/logs`,
    disabled: false,
  },
];

export const CreateLogBreadcrumb = [
  {
    text: "Call Log",
    href: `/call-log-admin`,
    disabled: true,
  },
  {
    text: "Create New Log",
    href: `/call-log-admin/createlog`,
    disabled: false,
  },
];

export const InboundCallLogBreadcrumb = [
  {
    text: "Call Log",
    href: `/call-log`,
    disabled: true,
  },
  {
    text: "Inbound Farmer Calls",
    href: `/call-log/inboundcalls`,
    disabled: false,
  },
];

export const CompleteProfileBreadcrumb = [
  {
    text: "Call Log",
    href: `/call-log`,
    disabled: true,
  },
  {
    text: "Completed Profiles",
    href: `/call-log/completeprofile`,
    disabled: false,
  },
];

export const InCompleteProfileBreadcrumb = [
  {
    text: "Call Log",
    href: `/call-log`,
    disabled: true,
  },
  {
    text: "Incompleted Profiles",
    href: `/call-log/incompleteprofile`,
    disabled: false,
  },
];

export const TemporaryProfileBreadcrumb = [
  {
    text: "Call Log",
    href: `/call-log`,
    disabled: true,
  },
  {
    text: "Temporary Farmers",
    href: `/call-log/temporaryfarmers`,
    disabled: false,
  },
];

export const FarmersBreadcrumb = [
  {
    text: "Farmers",
    href: `/farmers`,
    disabled: false,
  },
];

export const FarmersProfileBreadcrumb = [
  {
    text: "Farmers",
    href: `/farmers`,
    disabled: true,
  },
  {
    text: "Farmers Profile",
    href: `/farmers-profile`,
    disabled: false,
  },
];

export const CompleteFarmersBreadcrumb = [
  {
    text: "Farmers",
    href: `/farmers`,
    disabled: true,
  },
  {
    text: "Complete Profiles",
    href: `/complete-profiles`,
    disabled: false,
  },
];

export const TemporaryFarmersBreadcrumb = [
  {
    text: "Farmers",
    href: `/farmers`,
    disabled: true,
  },
  {
    text: "Temporary Farmers",
    href: `/temporary-farmers`,
    disabled: false,
  },
];

export const TemporaryFarmersProfileBreadcrumb = [
  {
    text: "Farmers",
    href: `/farmers`,
    disabled: true,
  },
  {
    text: "Temporary Farmers",
    href: `/temporary-farmers`,
    disabled: true,
  },
  {
    text: "Temporary Farmers Profile",
    href: ``,
    disabled: false,
  },
];

export const IncompleteFarmersBreadcrumb = [
  {
    text: "Farmers",
    href: `/farmers`,
    disabled: true,
  },
  {
    text: "Incomplete Profiles",
    href: `/incomplete-profiles`,
    disabled: false,
  },
];

export const FlaggedAccountsBreadcrumb = [
  {
    text: "Farmers",
    href: `/farmers`,
    disabled: true,
  },
  {
    text: "Flagged Farmers",
    href: `/flagged-farmers`,
    disabled: false,
  },
];

export const EditFarmersProfileBreadcrumb = [
  {
    text: "Farmers",
    href: `/farmers`,
    disabled: true,
  },

  {
    text: "Edit Profile",
    href: `/edit-profile`,
    disabled: false,
  },
];

export const NotificationsBreadcrumb = [
  {
    text: "Notifications",
    href: `/notifications`,
    disabled: false,
  },
];

export const TransactionsBreadcrumb = [
  {
    text: "Transactions",
    href: `/transactions`,
    disabled: false,
  },
];

export const MessagesBreadcrumb = [
  {
    text: "Messages",
    href: `/messages`,
    disabled: false,
  },
];

export const TeammateBreadcrumb = [
  {
    text: "Teammate Management",
    href: `/teammate-management`,
    disabled: false,
  },
];

export const MessageTemplatesBreadcrumb = [
  {
    text: "Messages",
    href: `/messages`,
    disabled: true,
  },
  {
    text: "Message Template",
    href: `/messages/templates`,
    disabled: false,
  },
];
export const PendingMessageBreadcrumb = [
  {
    text: "Messages",
    href: `/messages`,
    disabled: true,
  },
  {
    text: "Pending Message",
    href: `/messages/pending`,
    disabled: false,
  },
];

export const ComposeMessageBreadcrumb = [
  {
    text: "Messages",
    href: `/messages`,
    disabled: true,
  },
  {
    text: "Compose Message",
    href: `/messages/compose-message`,
    disabled: false,
  },
];

export const OrderBreadcrumb = [
  {
    text: "Orders",
    href: `/orders`,
    disabled: false,
  },
];

export const OrderInfoBreadcrumb = [
  {
    text: "Orders",
    href: `/orders`,
    disabled: true,
  },
  {
    text: "Order Info",
    href: `/order/2`,
    disabled: false,
  },
];

export const OrderRefundsBreadcrumb = [
  {
    text: "Orders",
    href: `/orders`,
    disabled: true,
  },
  {
    text: "Refunds",
    href: `/order/refunds`,
    disabled: false,
  },
];

export const OrderRefundApplicationBreadcrumb = [
  {
    text: "Orders",
    href: `/orders`,
    disabled: true,
  },
  {
    text: "Refunds",
    href: `/orders/refunds`,
    disabled: true,
  },
  {
    text: "Refund Application",
    href: `/orders/refund/1`,
    disabled: false,
  },
];

export const OverdueOrdersBreadcrumb = [
  {
    text: "Orders",
    href: `/orders`,
    disabled: true,
  },
  {
    text: "Overdue Orders",
    href: `/orders/overdue-orders`,
    disabled: false,
  },
];

export const VendorPaymentBreadcrumb = [
  {
    text: "Orders",
    href: `/orders`,
    disabled: true,
  },
  {
    text: "Vendor Payments",
    href: `/orders/vendor-payments`,
    disabled: false,
  },
];

export const VendorPaymentInfoBreadcrumb = [
  {
    text: "Orders",
    href: `/orders`,
    disabled: true,
  },
  {
    text: "Vendor Payments",
    href: `/orders/vendor-payments`,
    disabled: true,
  },
  {
    text: "Vendor Payment Details",
    href: `/orders/vendor-payment/1`,
    disabled: false,
  },
];

export const VendorsBreadcrumb = [
  {
    text: "Orders",
    href: `/orders`,
    disabled: true,
  },
  {
    text: "Vendors ",
    href: `/orders/vendors`,
    disabled: false,
  },
];

export const VendorInfoBreadcrumb = [
  {
    text: "Orders",
    href: `/orders`,
    disabled: true,
  },
  {
    text: "Vendors",
    href: `/orders/vendors`,
    disabled: true,
  },
  {
    text: "Vendor Details",
    href: `/orders/vendor/1`,
    disabled: false,
  },
];

export const SessionsBreadcrumb = [
  {
    text: "USSD Sessions",
    href: `/ussd-sessions`,
    disabled: false,
  },
];

export const SessionActivitiesBreadcrumb = [
  {
    text: "USSD Sessions",
    href: `/ussd-sessions`,
    disabled: true,
  },
  {
    text: "Activities",
    href: `/ussd-sessions`,
    disabled: false,
  },
];

export const ComplaintsBreadcrumb = [
  {
    text: "Complaints",
    href: `/complaints`,
    disabled: false,
  },
];

export const CooperativeBreadcrumb = [
  {
    text: "Cooperative",
    href: `/cooperative`,
    disabled: false,
  },
];

export const PendingCooperativeBreadcrumb = [
  {
    text: "Cooperative",
    href: `/cooperative`,
    disabled: true,
  },
  {
    text: "Pending Cooperative",
    href: `pending-cooperative`,
    disabled: false,
  },
];

export const CreateCooperativeBreadcrumb = [
  {
    text: "Cooperative",
    href: `/cooperative`,
    disabled: true,
  },
  {
    text: "Create Cooperative",
    href: `create-cooperative`,
    disabled: false,
  },
];

export const FlaggedCooperativeBreadcrumb = [
  {
    text: "Cooperative",
    href: `/cooperative`,
    disabled: true,
  },
  {
    text: "Flagged Cooperative",
    href: `flagged-cooperative`,
    disabled: false,
  },
];

export const CooperativeInfoBreadcrumb = [
  {
    text: "Cooperative",
    href: `/cooperative`,
    disabled: true,
  },
  {
    text: "Cooperative Info",
    href: ``,
    disabled: false,
  },
];

export const AgentsBreadcrumb = [
  {
    text: "Agents",
    href: `/agents`,
    disabled: false,
  },
];

export const FlaggedAgentsBreadcrumb = [
  {
    text: "Agents",
    href: `/agents`,
    disabled: true,
  },
  {
    text: "Flagged Agents",
    href: `flagged-agents`,
    disabled: false,
  },
];

export const PendingAgentsBreadcrumb = [
  {
    text: "Agents",
    href: `/agents`,
    disabled: true,
  },
  {
    text: "Pending Agents",
    href: `/pending-agent-approval`,
    disabled: false,
  },
];

export const PendingAgentsApprovalBreadcrumb = [
  {
    text: "Agents",
    href: `/agents`,
    disabled: true,
  },
  {
    text: "Pending Agents",
    href: `/pending-agent-approval`,
    disabled: true,
  },
  {
    text: "Agent Approval",
    href: ``,
    disabled: false,
  },
];

export const AgentsProfileBreadcrumb = [
  {
    text: "Agents",
    href: `/agents`,
    disabled: true,
  },
  {
    text: "Agent Profile",
    href: ``,
    disabled: false,
  },
];

export const RetailComplaintsBreadcrumb = [
  {
    text: "Complaints",
    href: `/complaints`,
    disabled: true,
  },
  {
    text: "Retail",
    href: `/retail-complaints`,
    disabled: false,
  },
];

export const IVRBreadcrumb = [
  {
    text: "IVR Session",
    href: `/IVR`,
    disabled: false,
  },
];

export const CallToOrderBreadcrumb = [
  {
    text: "Call To Order",
    href: `/call-to-order`,
    disabled: false,
  },
];

export const CallToOrderInfoBreadcrumb = [
  {
    text: "Call To Order",
    href: `/call-to-order`,
    disabled: true,
  },

  {
    text: "Call To Order Info",
    href: ``,
    disabled: false,
  },
];

export const CartBreadcrumb = [
  {
    text: "Call To Order",
    href: `/call-to-order`,
    disabled: true,
  },

  {
    text: "Call To Order Info",
    href: ``,
    disabled: true,
  },

  {
    text: "Cart",
    href: ``,
    disabled: false,
  },
];

export const RetailBreadcrumb = [
  {
    text: "Retail",
    href: `/retail`,
    disabled: false,
  },
];

export const callsBreadcrumb = [
  {
    text: "Retail",
    href: `/retail`,
    disabled: true,
  },
  {
    text: "Calls",
    href: ``,
    disabled: false,
  },
];

export const followUpBreadcrumb = [
  {
    text: "Retail",
    href: `/retail`,
    disabled: true,
  },
  {
    text: "Follow Up",
    href: ``,
    disabled: false,
  },
];

export const salesAgentBreadcrumb = [
  {
    text: "Retail",
    href: `/retail`,
    disabled: true,
  },
  {
    text: "Sales Agent",
    href: ``,
    disabled: false,
  },
];

export const salesBreadcrumb = [
  {
    text: "Retail",
    href: `/retail`,
    disabled: true,
  },
  {
    text: "Sales",
    href: ``,
    disabled: false,
  },
];

export const CamapignBreadcrumb = [
  {
    text: "Campaign",
    href: `/campaign`,
    disabled: false,
  },
];

export const CamapignLogBreadcrumb = [
  {
    text: "Campaign",
    href: `/campaign`,
    disabled: true,
  },
  {
    text: "Campaign Log",
    href: `/campaign-log`,
    disabled: false,
  },
];

export const AddCampaignBreadcrumb = [
  {
    text: "Campaign",
    href: `/campaign`,
    disabled: true,
  },
  {
    text: "Add Campaign",
    href: `/add-campaign`,
    disabled: false,
  },
];

export const CampaignSummaryBreadcrumb = [
  {
    text: "Campaign",
    href: `/campaign`,
    disabled: true,
  },
  {
    text: "Campaign Summary",
    href: ``,
    disabled: false,
  },
];

export const EditCampaignBreadcrumb = [
  {
    text: "Campaign",
    href: `/campaign`,
    disabled: true,
  },
  {
    text: "Edit Campaign",
    href: ``,
    disabled: false,
  },
];

export const AgroFastCashBreadcrumb = [
  {
    text: "Loan",
    href: `/agrofastcash`,
    disabled: false,
  },
];

export const LoanOverviewBreadcrumb = [
  {
    text: "Loan Overview",
    href: `/loan-overview`,
    disabled: false,
  },
];

export const LoanAgentProfileBreadcrumb = [
  {
    text: "Agro Fast Cash",
    href: `/agrofastcash`,
    disabled: true,
  },
  {
    text: "Loan Agent Profile",
    href: `/agrofastcash`,
    disabled: false,
  },
];

export const LoanApplicationBreadcrumb = [
  {
    text: "Agro Fast Cash",
    href: `/agrofastcash`,
    disabled: true,
  },
  {
    text: "Loan Application",
    href: `/agrofastcash`,
    disabled: false,
  },
];

export const GroupLoanApplicationBreadcrumb = [
  {
    text: "Agro Fast Cash",
    href: `/agrofastcash`,
    disabled: true,
  },
  {
    text: "Loan Application",
    href: `/agrofastcash/loan-application`,
    disabled: true,
  },
  {
    text: "Group Loan Application",
    href: `/agrofastcash`,
    disabled: false,
  },
];

export const LoanApplicationReviewBreadcrumb = [
  {
    text: "Agro Fast Cash",
    href: `/agrofastcash`,
    disabled: true,
  },
  {
    text: "Loan Application",
    href: `/agrofastcash/loan-application`,
    disabled: true,
  },
  {
    text: "Group Loan Application",
    href: `/agrofastcash/loan-application`,
    disabled: true,
  },
  {
    text: "Loan Application Review",
    href: `/agrofastcash`,
    disabled: false,
  },
];

export const ActiveLoansBreadcrumb = [
  {
    text: "Agro Fast Cash",
    href: `/agrofastcash`,
    disabled: true,
  },
  {
    text: "Active Loans",
    href: `/agrofastcash`,
    disabled: false,
  },
];

export const OverduePaymentBreadcrumb = [
  {
    text: "Agro Fast Cash",
    href: `/agrofastcash`,
    disabled: true,
  },
  {
    text: "Overdue Payment",
    href: `/agrofastcash`,
    disabled: false,
  },
];

export const LoanGroupsBreadcrumb = [
  {
    text: "Agro Fast Cash",
    href: `/agrofastcash`,
    disabled: true,
  },
  {
    text: "Groups",
    href: `/agrofastcash`,
    disabled: false,
  },
];

export const AddGuarantorBreadcrumb = [
  {
    text: "Agro Fast Cash",
    href: `/agrofastcash`,
    disabled: true,
  },
  {
    text: "Loan Application",
    href: `/agrofastcash/loan-application`,
    disabled: true,
  },
  {
    text: "Group Loan Application",
    href: `/agrofastcash/loan-application`,
    disabled: true,
  },
  {
    text: "Loan Application Review",
    href: `/agrofastcash`,
    disabled: true,
  },
  {
    text: "Add Guarantor",
    href: `/add-guarantor`,
    disabled: false,
  },
];

export const CreateLoanPorfolioBreadcrumb = [
  {
    text: "Loans",
    href: `/agrofastcash`,
    disabled: true,
  },
  {
    text: "Create Loan",
    href: ``,
    disabled: false,
  },
];

export const LoanDetailsBreadcrumb = [
  {
    text: "Loans",
    href: `/agrofastcash`,
    disabled: true,
  },
  {
    text: "Create Loan",
    href: ``,
    disabled: true,
  },
  {
    text: "Update Loan Portfolio",
    href: ``,
    disabled: false,
  },
];

export const LoanRecoveryBreadcrumb = [
  {
    text: "Recovery",
    href: `/loan-recovery`,
    disabled: false,
  },
];

export const PendingRecoveryBreadcrumb = [
  {
    text: "Recovery",
    href: `/loan-recovery`,
    disabled: true,
  },
  {
    text: "Pending Recovery",
    href: `/pending-recovery`,
    disabled: false,
  },
];

export const LoanRecoveryLogBreadcrumb = [
  {
    text: "Recovery",
    href: `/loan-recovery`,
    disabled: true,
  },
  {
    text: "Loan Recovery Log",
    href: `/loan-recovery-log`,
    disabled: false,
  },
];

export const ProfileBreadcrumb = [
  {
    text: "Profile",
    href: `/profile`,
    disabled: true,
  },
];

export const PointsBreadcrumb = [
  {
    text: "Points",
    href: `/points`,
    disabled: true,
  },
];

export const PointBreadcrumb = [
  {
    text: "Points",
    href: `/points`,
    disabled: true,
  },
  {
    text: "Farmer's Point",
    href: ``,
    disabled: false,
  },
];

export const PointSettingsBreadcrumb = [
  {
    text: "Points",
    href: `/points`,
    disabled: true,
  },
  {
    text: "Point settings",
    href: ``,
    disabled: false,
  },
];

export const KYCDocumentationBreadcrumb = [
  {
    text: "Farmer",
    href: `/farmers`,
    disabled: true,
  },
  {
    text: "Farmer Profile",
    href: ``,
    disabled: true,
  },

  {
    text: "Farmer KYC Documentation",
    href: ``,
    disabled: true,
  },
];

export const DebitFarmerBreadcrumb = [
  {
    text: "Debit Farmer",
    href: `/debit-farmer`,
    disabled: true,
  },
];
