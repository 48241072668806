import React from "react";

// For Seeds

const CropCategoryFive = ({ categoryFiveData, setCategoryFiveData }) => {
  return (
    <div>
      {" "}
      <div className="relative   rounded-lg bg-white h-fit border border-[#E1E6E1] mt-3">
        <div className="flex flex-col">
          <div className="border-b border-b-[#E1E6E1] px-4 py-3">
            <p className="text-[#4B564D] font-semibold text-sm">
              Product Details
            </p>
          </div>

          <div className="flex flex-col gap-4 px-4 py-3">
            <div>
              <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                Vendor
              </p>
              <input
                type="text"
                onChange={(event) =>
                  setCategoryFiveData((prev) => {
                    return {
                      ...prev,
                      vendor: event.target.value,
                    };
                  })
                }
                value={categoryFiveData.vendor}
                placeholder="Vendor"
                className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
              />
            </div>

            <div>
              <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                Seed Rate
              </p>
              <input
                type="text"
                placeholder="Seed Rate"
                onChange={(event) =>
                  setCategoryFiveData((prev) => {
                    return {
                      ...prev,
                      seedRate: event.target.value,
                    };
                  })
                }
                value={categoryFiveData.seedRate}
                className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
              />
            </div>

            <div>
              <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                Adaptability
              </p>
              <textarea
                type="text"
                placeholder="Adaptability"
                onChange={(event) =>
                  setCategoryFiveData((prev) => {
                    return {
                      ...prev,
                      adaptability: event.target.value,
                    };
                  })
                }
                value={categoryFiveData.adaptability}
                className="outline-none border border-[#96A397] resize-none w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
              />
            </div>

            <div>
              <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                Requirements{" "}
              </p>
              <textarea
                type="text"
                placeholder="Requirements"
                onChange={(event) =>
                  setCategoryFiveData((prev) => {
                    return {
                      ...prev,
                      requirements: event.target.value,
                    };
                  })
                }
                value={categoryFiveData.requirements}
                className="outline-none border border-[#96A397] resize-none w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropCategoryFive;
