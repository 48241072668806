import React, { useState } from "react";
import ModalComponent from "../../components/Modals/modal";
import { getAllState } from "../../redux/Location/locationAction";
import { useDispatch, useSelector } from "react-redux";
import { ExpandMore } from "@mui/icons-material";

const StoreModal = ({ isStoreModalOpen, setIsStoreModalOpen }) => {
  const dispatch = useDispatch();
  const [selectedState, setSelectedState] = useState({});
  const [isClickState, setIsClickState] = useState(false);
  const { stateData } = useSelector((state) => state.location);

  const handleStateClick = () => {
    setIsClickState(!isClickState);
    dispatch(getAllState());
  };

  const handleSelectedState = (data) => {
    setSelectedState({
      key: data.id,
      name: data.display_name,
    });
    setIsClickState(false);
  };

  return (
    <div>
      <ModalComponent
        show={isStoreModalOpen}
        showModal={() => setIsStoreModalOpen(false)}
        title="Add Store"
        subtitle=""
      >
        <div className="flex flex-col gap-4 mt-5">
          <div>
            <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
              Name of the store
            </p>
            <input
              type="text"
              placeholder="Name of the store"
              className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
            />
          </div>

          <div>
            <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
              Address
            </p>
            <input
              type="text"
              placeholder="Name of the store"
              className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
            />
          </div>

          <div className="flex gap-3">
            <div className="w-1/2">
              <div>
                <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                  State
                </p>
                <div className="relative w-full">
                  <div
                    className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                    onClick={handleStateClick}
                  >
                    <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                      {selectedState.name || "Select State"}
                    </p>
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  {isClickState && (
                    <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                      {stateData?.map((data, index) => {
                        return (
                          <p
                            className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                            key={index}
                            onClick={() => handleSelectedState(data)}
                          >
                            {data.display_name}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div>
                <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                  City
                </p>
                <div className="relative w-full">
                  <div
                    className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                    onClick={handleStateClick}
                  >
                    <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                      {selectedState.name || "Select City"}
                    </p>
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  {isClickState && (
                    <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                      {stateData?.map((data, index) => {
                        return (
                          <p
                            className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                            key={index}
                            onClick={() => handleSelectedState(data)}
                          >
                            {data.display_name}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
              Phone Number (Optional)
            </p>
            <input
              type="tel"
              placeholder="+234"
              className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
            />
          </div>

          <button
            disabled={true}
            className="outline-none mt-2 cursor-pointer bg-primary border disabled:bg-[#ABB6AC] disabled:border-[#ABB6AC] border-primary text-white font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px]  hover:border hover:border-primary  transition ease-in-out delay-100"
          >
            Add store
          </button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default StoreModal;
