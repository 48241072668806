import React, { useCallback, useEffect, useState } from "react";
import ModalComponent from "../../components/Modals/modal";
import {
  AddPhotoAlternateOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import cogoToast from "cogo-toast";

const VariantModal = ({
  isVariantModalOpen,
  setIsVariantModalOpen,
  selectedState,
  setSelectedState,
  productName,
  stores,
  setStores,
}) => {
  const [file, setFile] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedVariantId, setSelectedVariantId] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  // const [tab, setTab] = useState(stores[0].name);

  const handleQuantity = (e, id) => {
    const newQuantity = Number(e.target.value);
    setStores((prevStores) =>
      prevStores.map((store) =>
        store.id === id ? { ...store, quantity: newQuantity } : store
      )
    );
  };

  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    const total = stores.reduce((acc, currentValue) => {
      return acc + currentValue.quantity;
    }, 0);
    setTotalQuantity(total);
  }, [stores]);

  // Function to handle the delete button click
  const handleDeleteClick = (id) => {
    setSelectedVariantId(id);
    setOpenDeleteModal(true);
  };

  const confirmDelete = () => {
    setSelectedState((prevState) =>
      prevState.filter((state) => state.id !== selectedVariantId)
    );
    cogoToast.error(`Variant has been deleted`);
    setOpenDeleteModal(false);
    setSelectedVariantId(null);
  };

  return (
    <div>
      <ModalComponent
        show={isVariantModalOpen}
        style={{ maxWidth: "1000px" }}
        showModal={() => setIsVariantModalOpen(false)}
        title="Variant Quantity and Pricing "
        subtitle=""
      >
        <div className="w-full flex gap-4">
          <div className="w-[30%]">
            <div className="rounded-lg bg-white h-fit border border-[#E1E6E1] mt-3">
              <div className="flex flex-col mb-3">
                <div className="border-b border-b-[#E1E6E1] px-4 py-3  flex justify-between items-center">
                  <p className="text-[#4B564D] font-semibold text-sm">
                    {productName || "Nill"}
                  </p>

                  <p className="text-[#5C715E] font-medium text-xs">
                    {selectedState.length} Variants
                  </p>
                </div>

                <div>
                  {selectedState?.map((state, index) => {
                    const lastState = selectedState[selectedState.length - 1];
                    return (
                      <div
                        key={index}
                        className={`flex items-center  cursor-pointer ${
                          lastState.name === state.name
                            ? " pt-4"
                            : "border-b-[#E1E6E1] border-b py-4 "
                        } justify-between  px-4`}
                      >
                        <p className="text-[#343B35] font-semibold text-sm">
                          {state?.name}
                        </p>
                        <DeleteOutlineOutlined
                          style={{ color: "#344335", fontSize: "20px" }}
                          onClick={() => {
                            handleDeleteClick(state.id);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="w-[70%]">
            <div className="rounded-lg bg-[#F6F7F6] h-fit border border-[#E1E6E1] mt-3 p-4">
              <div className="">
                {/* PRODUCT IMAGE */}
                <div className="w-full h-[84px] flex bg-white justify-center items-center border border-[#E1E6E1] rounded-lg ">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <div>
                        {file.length !== 0 ? (
                          <div className="flex gap-3 flex-wrap"></div>
                        ) : (
                          <div className="flex flex-col justify-center items-center cursor-pointer ">
                            <AddPhotoAlternateOutlined
                              style={{ fontSize: "24px", color: "#2B8C34" }}
                            />
                            <p className="text-[#4B564D] font-medium text-sm  text-center mt-[10px]">
                              Upload a .jpeg or .png file of the product
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/* PRODUCT PRICING */}
                <div className="rounded-lg bg-white h-fit border border-[#E1E6E1] mt-3">
                  <div className="flex flex-col gap-4 mb-3">
                    <div className="border-b border-b-[#E1E6E1] px-4 py-3 pb-3">
                      <p className="text-[#4B564D] font-semibold text-sm">
                        Product Pricing
                      </p>
                    </div>
                    <div className="px-4">
                      <div className="flex gap-6">
                        <div>
                          <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                            Price
                          </p>
                          <input
                            type="number"
                            placeholder="₦"
                            className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                          />
                        </div>

                        <div>
                          <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                            Discount Price
                          </p>
                          <input
                            type="number"
                            placeholder="₦"
                            className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                          />
                        </div>
                      </div>
                      <div className="border-b border-b-[#E1E6E1] py-2"></div>
                      <div className="flex gap-6 mt-3">
                        <div className="flex-1">
                          <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                            Cost price of the item
                          </p>
                          <input
                            type="number"
                            placeholder="₦"
                            className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                          />
                        </div>

                        <div className="flex-1">
                          <p className="text-[#ABB6AC] font-normal text-sm leading-[18px] pb-1">
                            Profit
                          </p>
                          <input
                            type="number"
                            disabled
                            placeholder="₦"
                            className="outline-none disabled:bg-[#F7F7F7] border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                          />
                        </div>

                        <div className="flex-1">
                          <p className="text-[#ABB6AC] font-normal text-sm leading-[18px] pb-1">
                            Margin
                          </p>
                          <input
                            type="number"
                            disabled
                            placeholder="₦"
                            className="outline-none border border-[#96A397] disabled:bg-[#F7F7F7]  w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Stores */}
                <div className="rounded-lg z-10 relative bg-white h-fit border border-[#E1E6E1] mt-3">
                  <div className="flex flex-col">
                    <div className="border-b border-b-[#E1E6E1] px-4 py-3">
                      <p className="text-[#4B564D] font-semibold text-sm">
                        Inventory
                      </p>
                    </div>
                    <div className="px-4">
                      {stores?.map((store, index) => {
                        return (
                          <div
                            key={index}
                            className={`flex justify-between items-center py-4 border-b border-b-[#EDECE7] `}
                          >
                            <p className="text-[#4B564D] font-semibold text-sm">
                              {store.name}
                            </p>
                            <div className="flex border items-center gap-2 rounded-lg  px-3 w-fit">
                              <p className="text-[#4B564D] font-semibold text-sm py-2">
                                Quantity
                              </p>
                              <p className="text-[#4B564D] font-bold text-base py-2">
                                :
                              </p>

                              <input
                                type="text"
                                value={store.quantity}
                                onChange={(e) => handleQuantity(e, store.id)}
                                className="border-none outline-none w-[25px] max-w-[40px] text-[#4B564D] font-semibold text-sm"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="px-3 py-3">
                      <div className="flex justify-center items-center h-[42px] bg-[#F6F7F6] border border-[#E1E6E1] rounded-lg">
                        <p className="text-[#344335] font-semibold text-sm">
                          Total Quantity: {totalQuantity}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end  mt-4">
              <button
                disabled={false}
                className="outline-none cursor-pointer bg-primary border disabled:bg-[#ABB6AC] disabled:border-[#ABB6AC] border-primary text-white font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px]  hover:border hover:border-primary  transition ease-in-out delay-100"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        show={openDeleteModal}
        showModal={() => setOpenDeleteModal(false)}
        title="Delete Variant"
        style={{ maxWidth: "460px" }}
      >
        <p className="text-[#4B564D] text-sm mb-8 mt-4">
          Are you sure you want to delete variant{" "}
          <span className="font-semibold">
            {
              selectedState.find((state) => state.id === selectedVariantId)
                ?.name
            }
          </span>
          ?
        </p>
        <div className="flex justify-end gap-5">
          <button
            className="px-4 text-sm font-medium py-2 text-[#5C715E] rounded-md"
            onClick={() => setOpenDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className="bg-[#B92020] text-sm font-medium text-white px-4 py-2 rounded-md"
            onClick={confirmDelete}
          >
            Delete
          </button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default VariantModal;
