import React from "react";

// All Herbicides_Insectides_Plant growth solution_Fungicides

const CropCategoryOne = ({ categoryOneData, setCategoryOneData }) => {
  return (
    <div>
      {" "}
      <div className="relative   rounded-lg bg-white h-fit border border-[#E1E6E1] mt-3">
        <div className="flex flex-col">
          <div className="border-b border-b-[#E1E6E1] px-4 py-3">
            <p className="text-[#4B564D] font-semibold text-sm">
              Product Details
            </p>
          </div>

          <div className="flex flex-col gap-4 px-4 py-3">
            <div>
              <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                Vendor
              </p>
              <input
                type="text"
                onChange={(event) =>
                  setCategoryOneData((prev) => {
                    return {
                      ...prev,
                      vendor: event.target.value,
                    };
                  })
                }
                value={categoryOneData.vendor}
                placeholder="Vendor"
                className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
              />
            </div>

            <div>
              <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                Product Class
              </p>
              <input
                type="text"
                placeholder="Product Class"
                onChange={(event) =>
                  setCategoryOneData((prev) => {
                    return {
                      ...prev,
                      productClass: event.target.value,
                    };
                  })
                }
                value={categoryOneData.productClass}
                className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
              />
            </div>

            <div>
              <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                Dose Rate
              </p>
              <input
                type="text"
                placeholder="Dose Rate"
                onChange={(event) =>
                  setCategoryOneData((prev) => {
                    return {
                      ...prev,
                      doseRate: event.target.value,
                    };
                  })
                }
                value={categoryOneData.doseRate}
                className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
              />
            </div>

            <div>
              <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                Application Time
              </p>
              <textarea
                type="text"
                placeholder="Application Time"
                onChange={(event) =>
                  setCategoryOneData((prev) => {
                    return {
                      ...prev,
                      applicationTime: event.target.value,
                    };
                  })
                }
                value={categoryOneData.applicationTime}
                className="outline-none border border-[#96A397] resize-none w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
              />
            </div>

            <div>
              <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                Target Crops
              </p>
              <textarea
                type="text"
                placeholder="Target Crops"
                onChange={(event) =>
                  setCategoryOneData((prev) => {
                    return {
                      ...prev,
                      targetCrops: event.target.value,
                    };
                  })
                }
                value={categoryOneData.targetCrops}
                className="outline-none border border-[#96A397] resize-none w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
              />
              <p className="text-[#5C715E] text-xs font-medium">
                Use a comma(,) to separate different crops
              </p>
            </div>

            <div>
              <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                Mixing and Application
              </p>
              <textarea
                type="text"
                placeholder="Application Time"
                onChange={(event) =>
                  setCategoryOneData((prev) => {
                    return {
                      ...prev,
                      mixingAndApplication: event.target.value,
                    };
                  })
                }
                value={categoryOneData.mixingAndApplication}
                className="outline-none border border-[#96A397] resize-none w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
              />
            </div>

            <div>
              <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                Active ingredient(s)
              </p>
              <textarea
                type="text"
                placeholder="Active ingredient(s)"
                onChange={(event) =>
                  setCategoryOneData((prev) => {
                    return {
                      ...prev,
                      activeIngredients: event.target.value,
                    };
                  })
                }
                value={categoryOneData.activeIngredients}
                className="outline-none border border-[#96A397] resize-none w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropCategoryOne;
