import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import ForgotPasswordPage from "./pages/Auth/forgot";
import LoginPage from "./pages/Auth/login";
import ResetPassword from "./pages/Auth/reset";
import ResetSuccessPage from "./pages/Auth/resetSuccess";
import ProductCatalogPage from "./pages/ProductCatalogue/catalog";
import Farmers from "./pages/Farmers";
import {
  CatalogBreadcrumb,
  OverviewBreadcrumb,
  FarmersBreadcrumb,
  IncompleteFarmersBreadcrumb,
  TemporaryFarmersBreadcrumb,
  CompleteFarmersBreadcrumb,
  FlaggedAccountsBreadcrumb,
  FarmersProfileBreadcrumb,
  EditFarmersProfileBreadcrumb,
  NotificationsBreadcrumb,
  TransactionsBreadcrumb,
  SessionsBreadcrumb,
  SessionActivitiesBreadcrumb,
  CallRequestBreadcrumb,
  ComplaintsBreadcrumb,
  IVRBreadcrumb,
  RetailComplaintsBreadcrumb,
  CallLogBreadcrumb,
  InboundCallLogBreadcrumb,
  CompleteProfileBreadcrumb,
  InCompleteProfileBreadcrumb,
  CallToOrderBreadcrumb,
  TemporaryProfileBreadcrumb,
  MessagesBreadcrumb,
  MessageTemplatesBreadcrumb,
  CallToOrderInfoBreadcrumb,
  ComposeMessageBreadcrumb,
  OrderBreadcrumb,
  OrderInfoBreadcrumb,
  OrderRefundsBreadcrumb,
  OrderRefundApplicationBreadcrumb,
  OverdueOrdersBreadcrumb,
  VendorPaymentBreadcrumb,
  VendorPaymentInfoBreadcrumb,
  VendorsBreadcrumb,
  VendorInfoBreadcrumb,
  CallAgentBreadcrumb,
  CallLogsBreadcrumb,
  CreateLogBreadcrumb,
  CartBreadcrumb,
  RetailBreadcrumb,
  callsBreadcrumb,
  followUpBreadcrumb,
  salesBreadcrumb,
  salesAgentBreadcrumb,
  CamapignBreadcrumb,
  TemporaryFarmersProfileBreadcrumb,
  CamapignLogBreadcrumb,
  AddCampaignBreadcrumb,
  CampaignSummaryBreadcrumb,
  EditCampaignBreadcrumb,
  AgroFastCashBreadcrumb,
  LoanAgentProfileBreadcrumb,
  LoanApplicationBreadcrumb,
  ActiveLoansBreadcrumb,
  OverduePaymentBreadcrumb,
  LoanGroupsBreadcrumb,
  LoanApplicationReviewBreadcrumb,
  ProfileBreadcrumb,
  GroupLoanApplicationBreadcrumb,
  AddGuarantorBreadcrumb,
  LoanDetailsBreadcrumb,
  CreateLoanPorfolioBreadcrumb,
  LoanRecoveryBreadcrumb,
  PendingRecoveryBreadcrumb,
  LoanRecoveryLogBreadcrumb,
  LoanOverviewBreadcrumb,
  CooperativeBreadcrumb,
  PendingCooperativeBreadcrumb,
  FlaggedCooperativeBreadcrumb,
  AgentsBreadcrumb,
  AgentsProfileBreadcrumb,
  FlaggedAgentsBreadcrumb,
  PendingAgentsBreadcrumb,
  CreateCooperativeBreadcrumb,
  CooperativeInfoBreadcrumb,
  PendingAgentsApprovalBreadcrumb,
  PointsBreadcrumb,
  PointBreadcrumb,
  PointSettingsBreadcrumb,
  KYCDocumentationBreadcrumb,
  DebitFarmerBreadcrumb,
  PendingMessageBreadcrumb,
  inputBreadcrumb,
  addProductBreadcrumb,
  TeammateBreadcrumb,
} from "./helpers/crumbs";
import OverviewPage from "./pages/Overview/overview";
import NotFound from "./pages/404";
import CompleteProfile from "./pages/Farmers/CompleteProfiles";
import TemporaryFarmers from "./pages/Farmers/TemporaryFarmers";
import IncompleteProfiles from "./pages/Farmers/IncompleteFarmers";
import FlaggedFarmers from "./pages/Farmers/FlaggedFarmers";
import TemporaryFarmersProfile from "./pages/Farmers/TemporaryFarmers/TemporaryFarmersProfile";
import AddInfoCardPage from "./pages/ProductCatalogue/AddInfoCard";
import UpdateInfoCardPage from "./pages/ProductCatalogue/UpdateInfoCard";
import FarmersProfile from "./pages/Farmers/FarmersProfile";
import CallRequestPage from "./pages/CallRequest/callrequest";
import EditProfile from "./pages/Farmers/EditProfile";
import NotificationsPage from "./pages/Notifications/notifications";
import Transactions from "./pages/Transactions";
import USSDSessionsPage from "./pages/Sessions/ussdSessions";
import SessionActivitiesPage from "./pages/Sessions/sessionActivities";
import Complaints from "./pages/Complaints";
import IVR from "./pages/IVR";
import RetailComplaints from "./pages/Complaints/RetailComplaints";
import MemberLogPage from "./pages/CallLog/Member";
import MemberInboundCallsPage from "./pages/CallLog/Member/inboundCalls";
import MemberCompleteFarmersPage from "./pages/CallLog/Member/completeFarmers";
import MemberIncompleteFarmersPage from "./pages/CallLog/Member/incompleteFarmers";
import MemberTemporaryFarmersPage from "./pages/CallLog/Member/TemporaryFarmers";
import CallToOrder from "./pages/CallToOrder";
import MessagesPage from "./pages/Messages";
import MessageTemplatesPage from "./pages/Messages/templates";
import CallToOrderInfo from "./tables/CallToOrder/CallToOrderInfo";
import ComposeMessagePage from "./pages/Messages/composeMessage";
import OrdersPage from "./pages/Orders";
import OrderInfoPage from "./pages/Orders/orderInfo";
import RefundOrdersPage from "./pages/Orders/refundOrders";
import RefundApplicationPage from "./pages/Orders/refundApplication";
import OverdueOrdersPage from "./pages/Orders/overdueOrders";
import VendorPaymentsPage from "./pages/Orders/vendorPayments";
import VendorsPage from "./pages/Orders/Vendors";
import VendorInfoPage from "./pages/Orders/vendorInfo";
import AdminLogPage from "./pages/CallLog/TeamLead";
import CallAgentPage from "./pages/CallLog/TeamLead/callAgentPage";
import CallLogsPage from "./pages/CallLog/TeamLead/callLogs";
import CreateLogPage from "./pages/CallLog/TeamLead/createLog";
import Cart from "./pages/CallToOrder/Cart";
import Retail from "./pages/Retail";
import Calls from "./pages/Retail/Calls";
import FollowUp from "./pages/Retail/FollowUp";
import Sales from "./pages/Retail/Sales";
import SalesAgent from "./pages/Retail/SalesAgent";
import Campaign from "./pages/Campaign";
import CampaignLog from "./pages/Campaign/CampaignLog";
import AddCampaign from "./pages/Campaign/AddCampaign";
import CampaignSummary from "./pages/Campaign/CampaignSummary";
import EditCampaign from "./pages/Campaign/EditCampaign";
import VendorPaymentInfoPage from "./pages/Orders/vendorPaymentInfoPage";
import AgroFastCashPage from "./pages/Loans";
import LoanAgentProfilePage from "./pages/Loans/LoanAgentProfile/agentProfile";
import LoanApplicationPage from "./pages/Loans/loanApplication";
import ActiveLoansPage from "./pages/Loans/activeLoans";
import OverduePaymentLoansPage from "./pages/Loans/overduePayment";
import LoanGroupsPage from "./pages/Loans/Groups";
import LoanApplicationReview from "./pages/Loans/loanReview";
import ProfilePage from "./pages/Profile/profile";
import GroupLoanApplicationPage from "./pages/Loans/GroupLoanApplication";
import ProtectedRoute from "./components/PrivateRoutes/userRoute";
import AddGuarantor from "./pages/Loans/AddGuarantor";
import LoanDetails from "./pages/Loans/CreateLoan/LoanDetails";
import CreateLoanPorfolio from "./pages/Loans/CreateLoan/CreateLoanPorfolio";
import LoanRecoveryPage from "./pages/Loans/Recovery";
import PendingRecoveryPage from "./pages/Loans/Recovery/pendingRecovery";
import LoanRecoveryLogPage from "./pages/Loans/Recovery/loanRecoveryLog";
import LoanOverviewPage from "./pages/Loans/LoanOverview";
import Cooperative from "./pages/Cooperative";
import PendingCooperative from "./pages/Cooperative/pendingCooperative";
import FlaggedCooperative from "./pages/Cooperative/flaggedCooperative";
import Agents from "./pages/Agents";
import AgentsProfile from "./pages/Agents/AgentProfile/index";
import FlaggedAgents from "./pages/Agents/flaggedAgents";
import PendingAgents from "./pages/Agents/pendingAgents";
import CreateCooperative from "./pages/Cooperative/createCooperative";
import CooperativeInfo from "./pages/Cooperative/cooperative";
import ApprovalInfo from "./pages/Agents/ApprovalInfo";
import Points from "./pages/Points/points";
import Point from "./pages/Points/point";
import KYC from "./pages/Farmers/KYC documentation/kyc";
import DebitFarmer from "./pages/DebitFarmer";
import PointsSetting from "./tables/Points/settings";
import PendingMessages from "./pages/Messages/PendingMessages";
import Input from "./pages/ProductCatalogue/input";
import AddProduct from "./pages/Inventory/add-product";
import TeammatePage from "./pages/Teammate";
import JoinOrganisationPage from "./pages/Auth/joinOrganisation";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" exact element={<LoginPage />} />
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/reset-success" element={<ResetSuccessPage />} />
          <Route path="/join-organisation" element={<JoinOrganisationPage />} />
          <Route
            path="/overview"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={OverviewBreadcrumb}
                  children={<OverviewPage />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/call-request"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={CallRequestBreadcrumb}
                  children={<CallRequestPage />}
                />
              </ProtectedRoute>
            }
          />
          {/* admin logs */}
          <Route
            path="/call-log-admin"
            element={
              <Sidebar
                breadcrumb={CallLogBreadcrumb}
                children={<AdminLogPage />}
              />
            }
          />
          <Route
            path="/call-log-admin/logs"
            element={
              <Sidebar
                breadcrumb={CallLogsBreadcrumb}
                children={<CallLogsPage />}
              />
            }
          />
          <Route
            path="/call-log-admin/createlog"
            element={
              <Sidebar
                breadcrumb={CreateLogBreadcrumb}
                children={<CreateLogPage />}
              />
            }
          />
          <Route
            path="/call-log-admin/agent/:id"
            element={
              <Sidebar
                breadcrumb={CallAgentBreadcrumb}
                children={<CallAgentPage />}
              />
            }
          />
          {/* member logs */}
          <Route
            path="/call-log"
            element={
              <Sidebar
                breadcrumb={CallLogBreadcrumb}
                children={<MemberLogPage />}
              />
            }
          />
          <Route
            path="/call-log/inboundcalls"
            element={
              <Sidebar
                breadcrumb={InboundCallLogBreadcrumb}
                children={<MemberInboundCallsPage />}
              />
            }
          />
          <Route
            path="/call-log/completeprofile"
            element={
              <Sidebar
                breadcrumb={CompleteProfileBreadcrumb}
                children={<MemberCompleteFarmersPage />}
              />
            }
          />
          <Route
            path="/call-log/incompleteprofile"
            element={
              <Sidebar
                breadcrumb={InCompleteProfileBreadcrumb}
                children={<MemberIncompleteFarmersPage />}
              />
            }
          />
          <Route
            path="/call-log/temporaryprofile"
            element={
              <Sidebar
                breadcrumb={TemporaryProfileBreadcrumb}
                children={<MemberTemporaryFarmersPage />}
              />
            }
          />
          <Route
            path="/product-catalogue"
            element={
              <Sidebar
                breadcrumb={CatalogBreadcrumb}
                children={<ProductCatalogPage />}
              />
            }
          />
          <Route
            path="/input/:type"
            element={
              <Sidebar breadcrumb={inputBreadcrumb} children={<Input />} />
            }
          />
          <Route
            path="/add-product/:type"
            element={
              <Sidebar
                breadcrumb={addProductBreadcrumb}
                children={<AddProduct />}
              />
            }
          />
          <Route
            path="/product-catalogue/addinfocard/:category"
            element={
              <Sidebar
                breadcrumb={CatalogBreadcrumb}
                children={<AddInfoCardPage />}
              />
            }
          />
          <Route
            path="/product-catalogue/editinfocard/:category/:id"
            element={
              <Sidebar
                breadcrumb={CatalogBreadcrumb}
                children={<UpdateInfoCardPage />}
              />
            }
          />
          <Route
            path="/farmers"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={FarmersBreadcrumb}
                  children={<Farmers />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/farmers-profile/:id"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={FarmersProfileBreadcrumb}
                  children={<FarmersProfile />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/complete-profiles"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={CompleteFarmersBreadcrumb}
                  children={<CompleteProfile />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/temporary-farmers"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={TemporaryFarmersBreadcrumb}
                  children={<TemporaryFarmers />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/temporary-farmers-profile/:id"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={TemporaryFarmersProfileBreadcrumb}
                  children={<TemporaryFarmersProfile />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/incomplete-profiles"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={IncompleteFarmersBreadcrumb}
                  children={<IncompleteProfiles />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/flagged-farmers"
            element={
              <Sidebar
                breadcrumb={FlaggedAccountsBreadcrumb}
                children={<FlaggedFarmers />}
              />
            }
          />
          <Route
            path="/edit-profile/:id"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={EditFarmersProfileBreadcrumb}
                  children={<EditProfile />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <Sidebar
                breadcrumb={NotificationsBreadcrumb}
                children={<NotificationsPage />}
              />
            }
          />
          <Route
            path="/messages"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={MessagesBreadcrumb}
                  children={<MessagesPage />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messages/templates"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={MessageTemplatesBreadcrumb}
                  children={<MessageTemplatesPage />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messages/pending"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={PendingMessageBreadcrumb}
                  children={<PendingMessages />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messages/compose-message"
            element={
              <Sidebar
                breadcrumb={ComposeMessageBreadcrumb}
                children={<ComposeMessagePage />}
              />
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={OrderBreadcrumb}
                  children={<OrdersPage />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/order/:id/:type"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={OrderInfoBreadcrumb}
                  children={<OrderInfoPage />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/refunds"
            element={
              <Sidebar
                breadcrumb={OrderRefundsBreadcrumb}
                children={<RefundOrdersPage />}
              />
            }
          />
          <Route
            path="/orders/refund/:id"
            element={
              <Sidebar
                breadcrumb={OrderRefundApplicationBreadcrumb}
                children={<RefundApplicationPage />}
              />
            }
          />
          <Route
            path="/orders/overdue-orders"
            element={
              <Sidebar
                breadcrumb={OverdueOrdersBreadcrumb}
                children={<OverdueOrdersPage />}
              />
            }
          />
          <Route
            path="/orders/vendor-payments"
            element={
              <Sidebar
                breadcrumb={VendorPaymentBreadcrumb}
                children={<VendorPaymentsPage />}
              />
            }
          />
          <Route
            path="/orders/vendor-payment/:id/:product_type"
            element={
              <Sidebar
                breadcrumb={VendorPaymentInfoBreadcrumb}
                children={<VendorPaymentInfoPage />}
              />
            }
          />
          <Route
            path="/orders/vendors"
            element={
              <Sidebar
                breadcrumb={VendorsBreadcrumb}
                children={<VendorsPage />}
              />
            }
          />
          <Route
            path="/orders/vendor/:id/:product_type"
            element={
              <Sidebar
                breadcrumb={VendorInfoBreadcrumb}
                children={<VendorInfoPage />}
              />
            }
          />
          <Route
            path="/transactions"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={TransactionsBreadcrumb}
                  children={<Transactions />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ussd-sessions"
            element={
              <Sidebar
                breadcrumb={SessionsBreadcrumb}
                children={<USSDSessionsPage />}
              />
            }
          />
          <Route
            path="/ussd-sessions/activities"
            element={
              <Sidebar
                breadcrumb={SessionActivitiesBreadcrumb}
                children={<SessionActivitiesPage />}
              />
            }
          />
          <Route
            path="/complaints"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={ComplaintsBreadcrumb}
                  children={<Complaints />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/retail-complaints"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={RetailComplaintsBreadcrumb}
                  children={<RetailComplaints />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/IVR"
            element={<Sidebar breadcrumb={IVRBreadcrumb} children={<IVR />} />}
          />
          <Route
            path="/call-to-order"
            element={
              <Sidebar
                breadcrumb={CallToOrderBreadcrumb}
                children={<CallToOrder />}
              />
            }
          />
          <Route
            path="/call-to-order/:id"
            element={
              <Sidebar
                breadcrumb={CallToOrderInfoBreadcrumb}
                children={<CallToOrderInfo />}
              />
            }
          />
          <Route
            path="/cart/:id"
            element={
              <Sidebar breadcrumb={CartBreadcrumb} children={<Cart />} />
            }
          />
          <Route
            path="/retail"
            element={
              <Sidebar breadcrumb={RetailBreadcrumb} children={<Retail />} />
            }
          />

          <Route
            path="/calls"
            element={
              <Sidebar breadcrumb={callsBreadcrumb} children={<Calls />} />
            }
          />

          <Route
            path="/sales"
            element={
              <Sidebar breadcrumb={salesBreadcrumb} children={<Sales />} />
            }
          />

          <Route
            path="/retail/sales-agent/:id"
            element={
              <Sidebar
                breadcrumb={salesAgentBreadcrumb}
                children={<SalesAgent />}
              />
            }
          />

          <Route
            path="/follow-up"
            element={
              <Sidebar
                breadcrumb={followUpBreadcrumb}
                children={<FollowUp />}
              />
            }
          />

          <Route
            path="/campaign"
            element={
              <Sidebar
                breadcrumb={CamapignBreadcrumb}
                children={<Campaign />}
              />
            }
          />
          <Route
            path="/campaign-log"
            element={
              <Sidebar
                breadcrumb={CamapignLogBreadcrumb}
                children={<CampaignLog />}
              />
            }
          />

          <Route
            path="/add-campaign"
            element={
              <Sidebar
                breadcrumb={AddCampaignBreadcrumb}
                children={<AddCampaign />}
              />
            }
          />

          <Route
            path="/campaign-summary/:id"
            element={
              <Sidebar
                breadcrumb={CampaignSummaryBreadcrumb}
                children={<CampaignSummary />}
              />
            }
          />
          <Route
            path="/edit-campaign/:id"
            element={
              <Sidebar
                breadcrumb={EditCampaignBreadcrumb}
                children={<EditCampaign />}
              />
            }
          />
          <Route
            path="/loan-overview"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={LoanOverviewBreadcrumb}
                  children={<LoanOverviewPage />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agrofastcash"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={AgroFastCashBreadcrumb}
                  children={<AgroFastCashPage />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agrofastcash/loan-agent/:id"
            element={
              <Sidebar
                breadcrumb={LoanAgentProfileBreadcrumb}
                children={<LoanAgentProfilePage />}
              />
            }
          />
          <Route
            path="/agrofastcash/loan-application"
            element={
              <Sidebar
                breadcrumb={LoanApplicationBreadcrumb}
                children={<LoanApplicationPage />}
              />
            }
          />
          <Route
            path="/agrofastcash/loan-application/:group"
            element={
              <Sidebar
                breadcrumb={GroupLoanApplicationBreadcrumb}
                children={<GroupLoanApplicationPage />}
              />
            }
          />
          <Route
            path="/agrofastcash/loan-review/:id"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={LoanApplicationReviewBreadcrumb}
                  children={<LoanApplicationReview />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agrofastcash/active-loans"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={ActiveLoansBreadcrumb}
                  children={<ActiveLoansPage />}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agrofastcash/overdue-payment"
            element={
              <Sidebar
                breadcrumb={OverduePaymentBreadcrumb}
                children={<OverduePaymentLoansPage />}
              />
            }
          />
          <Route
            path="/agrofastcash/groups"
            element={
              <Sidebar
                breadcrumb={LoanGroupsBreadcrumb}
                children={<LoanGroupsPage />}
              />
            }
          />
          <Route
            path="/add-guarantor/:id"
            element={
              <Sidebar
                breadcrumb={AddGuarantorBreadcrumb}
                children={<AddGuarantor />}
              />
            }
          />
          <Route
            path="/create-loan-porfolio"
            element={
              <Sidebar
                breadcrumb={CreateLoanPorfolioBreadcrumb}
                children={<CreateLoanPorfolio />}
              />
            }
          />
          <Route
            path="/loan-details/:id"
            element={
              <Sidebar
                breadcrumb={LoanDetailsBreadcrumb}
                children={<LoanDetails />}
              />
            }
          />
          <Route
            path="/loan-recovery"
            element={
              <Sidebar
                breadcrumb={LoanRecoveryBreadcrumb}
                children={<LoanRecoveryPage />}
              />
            }
          />
          <Route
            path="/pending-recovery"
            element={
              <Sidebar
                breadcrumb={PendingRecoveryBreadcrumb}
                children={<PendingRecoveryPage />}
              />
            }
          />
          <Route
            path="/loan-recovery-log/:id"
            element={
              <Sidebar
                breadcrumb={LoanRecoveryLogBreadcrumb}
                children={<LoanRecoveryLogPage />}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <Sidebar
                breadcrumb={ProfileBreadcrumb}
                children={<ProfilePage />}
              />
            }
          />

          <Route
            path="/cooperative"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={CooperativeBreadcrumb}
                  children={<Cooperative />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/pending-cooperative"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={PendingCooperativeBreadcrumb}
                  children={<PendingCooperative />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/create-cooperative"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={CreateCooperativeBreadcrumb}
                  children={<CreateCooperative />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/flagged-cooperative"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={FlaggedCooperativeBreadcrumb}
                  children={<FlaggedCooperative />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/cooperative/:id"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={CooperativeInfoBreadcrumb}
                  children={<CooperativeInfo />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/agents"
            element={
              <ProtectedRoute>
                <Sidebar breadcrumb={AgentsBreadcrumb} children={<Agents />} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agent/:id"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={AgentsProfileBreadcrumb}
                  children={<AgentsProfile />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/flagged-agents"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={FlaggedAgentsBreadcrumb}
                  children={<FlaggedAgents />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/pending-agent-approval"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={PendingAgentsBreadcrumb}
                  children={<PendingAgents />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/agent-approval-info/:id"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={PendingAgentsApprovalBreadcrumb}
                  children={<ApprovalInfo />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/points"
            element={
              <ProtectedRoute>
                <Sidebar breadcrumb={PointsBreadcrumb} children={<Points />} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/points/:id"
            element={
              <ProtectedRoute>
                <Sidebar breadcrumb={PointBreadcrumb} children={<Point />} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/point-settings"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={PointSettingsBreadcrumb}
                  children={<PointsSetting />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/kyc-documentation/:id"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={KYCDocumentationBreadcrumb}
                  children={<KYC />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/debit-farmer"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={DebitFarmerBreadcrumb}
                  children={<DebitFarmer />}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/teammate-management"
            element={
              <ProtectedRoute>
                <Sidebar
                  breadcrumb={TeammateBreadcrumb}
                  children={<TeammatePage />}
                />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
