import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card, CardWithStatus } from "../../components/Cards/index";
import arrowDown from "../../assets/icons/keyboard_arrow_down.svg";
import BarChart from "../../components/Charts/BarChart";
import { useDispatch, useSelector } from "react-redux";
import {
  getBarChartsData,
  getOverviewInsights,
  getOverviewStats,
  getRecentOrders,
  getRecentTransactions,
} from "../../redux/Overview/overviewActions";
import { useNavigate } from "react-router-dom";

const OverviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stats, insights, recentTransactions, loading, loader, recentOrders } =
    useSelector((state) => state.overview);

  const [typeID, setTypeID] = useState(1);
  const [typeTitle, setTypeTitle] = useState("All");
  const orderTypes = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Input",
    },
    {
      id: 3,
      title: "Mechanization",
    },
    {
      id: 4,
      title: "Advisory",
    },
    {
      id: 5,
      title: "Insurance",
    },
  ];
  const toggleType = (id, title) => {
    setTypeID(id);
    setTypeTitle(title);
    switch (id) {
      case 1:
        dispatch(
          getRecentOrders({
            type: "all",
          })
        );
        break;
      case 2:
        dispatch(
          getRecentOrders({
            type: "input_orders",
          })
        );
        break;
      case 3:
        dispatch(
          getRecentOrders({
            type: "mechanization_orders",
          })
        );
        break;
      case 4:
        dispatch(
          getRecentOrders({
            type: "advisory_orders",
          })
        );
        break;
      case 5:
        dispatch(
          getRecentOrders({
            type: "insurance_orders",
          })
        );
        break;
      default:
        break;
    }
  };

  const [tabId, setTabID] = useState(1);
  const [transactId, setTransactID] = useState(1);
  const TabTypes = [
    {
      id: 1,
      title: "Recent Orders",
    },
    {
      id: 2,
      title: "Recent Transactions",
    },
  ];

  const transactionTypes = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Debit",
    },
    {
      id: 3,
      title: "Credit",
    },
  ];

  const toggleTab = (id) => {
    setTabID(id);
  };

  const toggleTransact = (id) => {
    setTransactID(id);
    switch (id) {
      case 1:
        dispatch(
          getRecentTransactions({
            type: "all",
            limit: 10,
          })
        );
        break;
      case 2:
        dispatch(
          getRecentTransactions({
            type: "debit",
            limit: 10,
          })
        );
        break;
      case 3:
        dispatch(
          getRecentTransactions({
            type: "credit",
            limit: 10,
          })
        );
        break;
      default:
        break;
    }
  };

  //  const numberWithCommas = (x) => {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }

  const insightDays = [
    {
      id: 1,
      name: "Last 7 days",
      tag: "7",
    },
    {
      id: 2,
      name: "Last 28 days",
      tag: "28",
    },
    {
      id: 3,
      name: "Last 90 days",
      tag: "90",
    },
    {
      id: 4,
      name: "Last 365 days",
      tag: "365",
    },
    {
      id: 5,
      name: "Lifetime",
      tag: "lifetime",
    },
  ];

  const [isSelected, setIsselected] = useState(1);
  const [selectedName, setSelectedName] = useState(null);
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "days").format("MMMM Do, YYYY")
  );
  const [toDate, setToDate] = useState(moment().format("MMMM Do, YYYY"));
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickInsight = (id, name) => {
    // setIsOpen(!isOpen)
    setIsselected(id);
    setSelectedName(name);
    // check for filter selected
    switch (id) {
      case 1:
        dispatch(
          getOverviewInsights({
            from: moment().subtract(7, "days").format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
          })
        );
        setFromDate(moment().subtract(7, "days").format("MMMM Do, YYYY"));
        setToDate(moment().format("MMMM Do, YYYY"));
        break;
      case 2:
        dispatch(
          getOverviewInsights({
            from: moment().subtract(28, "days").format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
          })
        );
        setFromDate(moment().subtract(28, "days").format("MMMM Do, YYYY"));
        setToDate(moment().format("MMMM Do, YYYY"));
        break;
      case 3:
        dispatch(
          getOverviewInsights({
            from: moment().subtract(90, "days").format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
          })
        );
        setFromDate(moment().subtract(90, "days").format("MMMM Do, YYYY"));
        setToDate(moment().format("MMMM Do, YYYY"));
        break;
      case 4:
        dispatch(
          getOverviewInsights({
            from: moment().subtract(365, "days").format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
          })
        );
        setFromDate(moment().subtract(365, "days").format("MMMM Do, YYYY"));
        setToDate(moment().format("MMMM Do, YYYY"));
        break;
      case 5:
        dispatch(
          getOverviewInsights({
            from: moment().subtract(3, "years").format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
          })
        );
        setFromDate(moment().subtract(3, "years").format("MMMM Do, YYYY"));
        setToDate(moment().format("MMMM Do, YYYY"));
        break;
      default:
        break;
    }

    setTimeout(() => {
      setIsOpen(!isOpen);
    }, 500);
  };

  // format status style
  const formatStatus = (val) => {
    let res;
    switch (val) {
      case null:
        res = "pending-badge";
        break;
      case "PENDING":
        res = "pending-badge";
        break;
      case "DELIVERED":
        res = "success-badge";
        break;
      case "CANCELLED":
        res = "error-badge";
        break;
      case "DISPATCHED":
        res = "info-badge";
        break;
      case "PROCESSING":
        res = "pending-badge";
        break;
      default:
        break;
    }
    return res;
  };

  const handleCapitalize = (str) => {
    let value = str.toLowerCase();
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const hanldeViewOrder = (id, product_type) => {
    let productType;
    if (product_type === "crop_input" || product_type === "input") {
      productType = "Input";
    } else if (
      product_type === "livestock_input" ||
      product_type === "livestcok"
    ) {
      productType = "Livestock";
    } else if (
      product_type === "mechanization_input" ||
      product_type === "mechanization"
    ) {
      productType = "Mechanization";
    } else if (
      product_type === "advisory_input" ||
      product_type === "advisory"
    ) {
      productType = "Advisory";
    } else if (
      product_type === "insurance_input" ||
      product_type === "insurance"
    ) {
      productType = "Insurance";
    }
    navigate(`/order/${id}/${productType}`);
  };

  useEffect(() => {
    dispatch(getOverviewStats());
    dispatch(
      getOverviewInsights({
        from: moment().subtract(7, "days").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getRecentTransactions({
        type: "all",
        limit: 10,
      })
    );
    dispatch(
      getRecentOrders({
        type: "all",
      })
    );
    dispatch(getBarChartsData());
  }, [dispatch]);

  return (
    <>
      <div className="bg-white flex justify-between rounded-lg py-5 px-4">
        <div>
          <h6 className="text-primary font-semibold text-lg">Overview</h6>
          <p className="text-[#5C715E] text-sm font-semibold mt-5">
            {moment().format("MMMM Do, YYYY")}
          </p>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <div className="grid grid-cols-3 gap-4">
            <div>
              <Card
                title="Total Number Of Farmers"
                subtitle={
                  stats ? Number(stats.total_farmers)?.toLocaleString() : 0
                }
                href="/farmers"
              />
            </div>
            <div>
              <CardWithStatus
                hasStatus={true}
                status="Urgent"
                title="Call to order"
                subtitle={
                  stats
                    ? Number(
                        stats.total_unchecked_call_to_orders
                      )?.toLocaleString()
                    : 0
                }
                href="/call-to-order"
              />
            </div>
            <div>
              <CardWithStatus
                hasStatus={true}
                status="Urgent"
                title="Overdue Orders"
                subtitle={
                  stats
                    ? Number(stats.total_overdue_orders)?.toLocaleString()
                    : 0
                }
                href="/orders/overdue-orders"
              />
            </div>
          </div>

          <div className="mt-4 bg-white rounded-lg py-6 px-6">
            <BarChart />
          </div>
        </div>
        <div className="bg-[#4E9F56] rounded-lg py-4 px-4 h-fit">
          <div className="flex justify-between items-center">
            <div>
              <h5 className="text-white font-semibold text-xl">Insights</h5>
            </div>
            <div className="relative">
              <small className="text-xs text-white  font-medium">
                {fromDate} – {toDate}
              </small>
              <div
                className="flex gap-2 items-center cursor-pointer justify-end"
                onClick={handleDropdown}
              >
                <p className="text-white text-sm font-semibold">
                  {selectedName ? selectedName : "Last 7 days"}
                </p>
                <img src={arrowDown} alt="arrow down" />
              </div>
              {isOpen && (
                <div
                  className="absolute  right-0 top-12 rounded-[6px] py-2 px-2 w-full bg-white z-10 min-w-[160px]"
                  style={{
                    boxShadow:
                      "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  {insightDays.map((val) => (
                    <div
                      key={val.id}
                      onClick={() => handleClickInsight(val.id, val.name)}
                      className={
                        val.id === isSelected
                          ? "px-4 py-3 bg-[#EDF7EE] border border-primary rounded-md hover:bg-[#EDF7EE] cursor-pointer"
                          : "px-4 py-3 rounded-md hover:bg-[#EDF7EE] cursor-pointer"
                      }
                    >
                      <p
                        className={
                          val.id === isSelected
                            ? "font-medium text-sm leading-5 text-[#24752B]"
                            : "font-medium text-sm leading-5 text-[#344335]"
                        }
                      >
                        {val.name}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="mt-6">
            <p className="text-white text-sm font-semibold">Farmers</p>
            <h6 className="text-white font-semibold text-lg">
              {insights ? insights.total_incomplete_farmers : 0} Incomplete
              Profile(s)
            </h6>
            <h6 className="text-white font-semibold text-lg">
              {insights ? insights.total_complete_farmers : 0} Completed
              Profile(s)
            </h6>
            <h6 className="text-white font-semibold text-lg">
              {insights ? insights.total_temporary_farmers : 0} Temporary
              Farmers(s)
            </h6>
          </div>
          <div className="mt-4">
            <p className="text-white text-sm font-semibold">Calls</p>
            <h6 className="text-white font-semibold text-lg">
              {insights ? insights.total_call_to_orders : 0} Call to order(s)
            </h6>
            <h6 className="text-white font-semibold text-lg">
              {insights ? insights.total_call_requests : 0} Call request(s)
            </h6>
          </div>
          <div className="mt-4">
            <p className="text-white text-sm font-semibold">Orders</p>
            <h6 className="text-white font-bold text-lg">
              {insights ? insights.total_orders_delivered : 0} Delivered
            </h6>
            <h6 className="text-white font-bold text-lg">
              {insights ? insights.total_orders_in_transit : 0} In Transit
            </h6>
            <h6 className="text-white font-bold text-lg">
              {insights ? insights.total_orders_processing : 0} Processing
            </h6>
          </div>
        </div>
      </div>

      <div className="mt-8 bg-white rounded-lg py-5 px-4">
        <ul className="flex gap-4 items-center">
          {TabTypes.map((val) => (
            <li
              key={val.id}
              onClick={() => toggleTab(val.id)}
              className="cursor-pointer"
            >
              <p
                className={
                  tabId === val.id
                    ? "text-primary text-base font-semibold border-b-2 border-primary"
                    : "text-[#96A397] text-base font-semibold"
                }
              >
                {val.title}
              </p>
            </li>
          ))}
        </ul>
      </div>

      {tabId === 1 && (
        <div className="mt-3 bg-white rounded-lg py-4">
          <ul className="flex gap-5 items-center px-4">
            {orderTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id, item.title)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
          <div className="overflow-x-auto mt-2 relative">
            <table className="w-full text-left">
              <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                <tr>
                  <th scope="col" className="px-4 py-4 font-semibold ">
                    Order ID
                  </th>

                  <th scope="col" className="px-4 py-4 font-semibold">
                    Amount Paid
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Farmer's Name
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Date/Time
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Payment Status
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Order Status
                  </th>
                </tr>
              </thead>
              {!loader && (
                <tbody>
                  {recentOrders?.length > 0
                    ? recentOrders?.map((item) => (
                        <tr
                          key={item.id}
                          className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                          onClick={() =>
                            hanldeViewOrder(
                              item?.id,
                              item?.product_type || typeTitle?.toLowerCase()
                            )
                          }
                        >
                          <th
                            scope="row"
                            className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                          >
                            {item.id}
                          </th>

                          <td className="py-4 px-4 text-[#5C715E]">
                            ₦{item.total_display_price}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {item.farmer.first_name} {item.farmer.last_name}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {item.created
                              ? moment(item.created).format(
                                  "Do, MMM YYYY hh:mm:ss a"
                                )
                              : ""}
                          </td>
                          <td className="py-4 px-4">
                            <div className="flex">
                              <div
                                className={
                                  item.payment_status
                                    ? "success-badge"
                                    : "error-badge"
                                }
                              >
                                <p>
                                  {item.payment_status ? "Paid" : "Not Paid"}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="py-4 px-4">
                            <div className="flex">
                              <div
                                className={
                                  item?.order_status
                                    ? item?.order_status?.length === 0
                                      ? "pending-badge"
                                      : formatStatus(
                                          item?.order_status[
                                            item?.order_status.length - 1
                                          ]
                                        )
                                    : "pending-badge"
                                }
                              >
                                <p>
                                  {item?.order_status
                                    ? item?.order_status?.length === 0
                                      ? "Pending"
                                      : handleCapitalize(
                                          item?.order_status[
                                            item?.order_status?.length - 1
                                          ]
                                        )
                                    : "Pending"}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              )}
            </table>
            {loader && (
              <div className="animate-pulse w-full mt-4 px-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            )}
          </div>
        </div>
      )}

      {tabId === 2 && (
        <div className="mt-3 bg-white rounded-lg py-4">
          <ul className="flex gap-5 items-center px-4">
            {transactionTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleTransact(item.id)}
                className={
                  transactId === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    transactId === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
          <div className="overflow-x-auto mt-2 relative">
            <table className="w-full text-left">
              <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                <tr>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    TXN ID
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Name
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Activity
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Amount
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Date
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Time
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    TXN Type
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    TXN Status
                  </th>
                </tr>
              </thead>
              {!loading && (
                <tbody>
                  {recentTransactions.length > 0
                    ? recentTransactions.map((item) => (
                        <tr
                          key={item.id}
                          className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                        >
                          <th
                            scope="row"
                            className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                          >
                            {item.id}
                          </th>

                          <td className="py-4 px-4 text-[#5C715E]">
                            {item.farmer.first_name +
                              " " +
                              item.farmer.last_name}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">N/A</td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            N{item.amount}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {item.created
                              ? moment(item.created).format("Do, MMM YYYY")
                              : ""}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {item.created
                              ? moment(item.created).format("hh:mm:ss a")
                              : ""}
                          </td>
                          <td className="py-4 px-4">
                            <div className="flex">
                              <div
                                className={
                                  item.transaction_type === "debit"
                                    ? "error-badge"
                                    : "success-badge"
                                }
                              >
                                <p>
                                  {item.transaction_type === "debit"
                                    ? "Debit"
                                    : "Credit"}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="py-4 px-4">
                            <div className="flex">
                              <div
                                className={
                                  item.status ? "success-badge" : "error-badge"
                                }
                              >
                                <p>{item.status ? "Successful" : "Failed"}</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              )}
            </table>

            {loading && (
              <div className="animate-pulse w-full mt-4 px-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OverviewPage;
